<template>
    <div>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Employee Details</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    <router-link :to="{name: 'employees'}">Employees</router-link>
                </li>
                <li class="breadcrumb-item active" v-if="employee" aria-current="page">{{employee.full_name}} [IES-{{$route.params.id}}]</li>
            </ol>
        </nav>
        <br />
        <b-container fluid v-model="page_loader" class="mb-3">
            <div v-if="api_error">
                <b-alert
                :show="alertDismissCountDown"
                dismissible
                variant="danger"
                @dismissed="alertDismissCountDown=0"
                @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <span v-if="employee">
                <div>
                    <h4 class="d-inline">Employee Details</h4>
                    <button
                        type="button"
                        class="btn btn-primary float-right"
                        @click="$router.push({name: 'payroll', params: {emp_id:$route.params.id}})"
                    >Payroll&nbsp;<font-awesome-icon icon="rupee-sign" class="ml-1"></font-awesome-icon></button>
                    <button
                        type="button"
                        v-if="hasPermission('CAN_UPDATE_EMPLOYEE') && employee_details"
                        class="btn btn-danger float-right mr-1"
                        @click="$router.push({ name: 'edit-employee', params: { id:  $route.params.id}})"
                    >Edit&nbsp;<font-awesome-icon icon="pen" class="ml-1"></font-awesome-icon></button>
                    <button
                        type="button"
                        class="btn btn-light float-right mr-1"
                        @click="$router.push({name: 'credit-debit-leaves', params: {emp_id:$route.params.id}})"
                    >Credit/Debit Leaves&nbsp;</button>
                    <b-table striped hover :items="employee_details" thead-class="d-none" class="mb-5 mt-3">
                        <template #cell(label)="data">
                            <span v-html="data.value"></span>
                        </template>
                        <template #cell(value)="data">
                            <div>
                                <b-badge 
                                    v-if="data.value == 'ACTIVE' || data.value == 'RESIGNED' || data.value == 'SUSPENDED' || data.value == 'HOLD' " 
                                    :variant="setBadgeColor(data.value)">{{data.value}}</b-badge>
                                <span v-else>{{data.value}}</span>
                            </div>                        
                        </template>
                    </b-table>
                </div>
                <!-- personal Details -->

                <div>
                    <h4 class="my-3">Personal Details</h4>
                    <b-table striped hover :items="personal_details" thead-class="d-none" class="mb-5 sometbl" >
                        <template #cell(label)="data">
                            <span v-html="data.value"></span>
                        </template>
                    </b-table>

                </div>

                <!-- Bank Details -->

                <div >
                    <h4 class="my-3">Bank Details</h4>
                    <b-table striped hover :items="bank_details" thead-class="d-none" class="mb-5 sometbl" >
                        <template #cell(label)="data">
                            <span v-html="data.value"></span>
                        </template>
                    </b-table>

                </div>

                <div>
                    <h4 class="my-3">Salary Details</h4>
                    <b-table striped hover :items="salary_details" thead-class="d-none" class="mb-5">
                        <template #cell(label)="data">
                            <span v-html="data.value"></span>
                        </template>
                    </b-table>

                    <div class="form-group row float-right" >
                        <div class="offset-xs-3 col-xs-9" v-if="hasPermission('CAN_MANAGE_EMP_SALARIES') && salary_details.length >0">
                            <button
                                type="button"
                                class="btn btn-danger text-right ma-10px"
                                @click="$router.push({name:'employee-salary-correction', params:{emp_id:$route.params.id}})"
                            >Salary Correction</button>
                        </div>
                        <div class="offset-xs-3 col-xs-9" v-if="hasPermission('CAN_MANAGE_EMP_SALARIES')">
                            <button
                                type="button"
                                class="btn btn-success text-right ma-10px"
                                @click="$router.push({name:'employee-salary-appraisal', params:{emp_id:$route.params.id}})"
                            >Salary Appraisal</button>
                        </div>

                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                class="btn btn-info ma-10px"
                                @click="$router.push({name:'employee-salary-history', params:{emp_id:$route.params.id}})"
                            >Salary History</button>
                        </div>
                    </div>
                </div>
            </span>  

            <div v-else class="text-center">
                <span>
                    <font-awesome-icon icon="spinner" spin size="2x"></font-awesome-icon>
                </span>
            </div>     
        </b-container>
    </div>
</template>

<script>
import default_error from "../../../assets/js/global";
export default {
    data() {
        return {
            api_error: "",
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            employee: null,
            page_loader: false,
            employee_salary:{
                effective_from: "",
                slab_id:"",
                emp_id:"",
                offered_gross_per_month:"",
                offered_gross_per_year:""
            },
            employee_details: [],
            personal_details: [],
            bank_details: [],
            salary_details: [],
            badge_colors:[
                {
                    status: "ACTIVE",
                    color:'success'
                },
                {
                    status: "RESIGNED",
                    color:'danger'
                },
                {
                    status: "HOLD",
                    color:'danger'
                },
                {
                    status: "SUSPENDED",
                    color:'warning'
                }
            ],
        };
    },
    mounted() {
        this.getEmployee();
        this.getEmployeeSalary();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        getEmployee: function() {
            this.page_loader= true;
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = {
                id: this.$route.params.id
            };
            this.axios
                .get(this.$api.get_employees, query)
                .then(response => {
                    this.employee = response.data.data;
                    this.employee_details = [
                        { label: "<b> Employee ID </b> <b style='float:right'>:</b>", value: "IES-" + this.employee.id,text:"",text2:""  },
                        { label: "<b> Employee Name </b> <b style='float:right'>:</b>", value: this.employee.full_name,text:"",text2:""  },
                        { label: "<b>Login Email </b> <b style='float:right'>:</b>",  value: this.employee.user.email,text:"",text2:""  },
                        { label: "<b>Date of Joining </b> <b style='float:right'>:</b>", value: this.dateformat(this.employee.doj),text:"",text2:""  },
                        { label: "<b>Designation </b> <b style='float:right'>:</b>",  value: this.employee.designation,text:"",text2:""  },
                        { label: "<b>Role </b> <b style='float:right'>:</b>",  value: this.getRole(this.employee.role),text:"",text2:""  },
                        { label:  "<b>Employee Group </b> <b style='float:right'>:</b>",  value: this.employee.employee_group.name,text:"",text2:""  },
                        { label:  "<b>Holiday Group </b> <b style='float:right'>:</b>",  value: this.employee.holiday_group.name,text:"",text2:""  },
                        { label: "<b>Leave Group </b> <b style='float:right'>:</b>",  value: this.employee.leave_group.name,text:"",text2:""  },
                        { label: "<b>Week Offs </b> <b style='float:right'>:</b>",  value: this.hashTags(this.employee.week_off, 'wee'),text:"",text2:""  },
                        { label: "<b>Reporting To </b> <b style='float:right'>:</b>", value: this.employee.reporting_to.full_name+' [IES-' + this.employee.reporting_to.id + ']',text:"",text2:"" },
                        { label: "<b>Employee Status </b> <b style='float:right'>:</b>",  value: this.employee.status,text:"",text2:""  },
                        { label: "<b>Payroll Status </b> <b style='float:right'>:</b>",  value: this.employee.payroll_status,text:"",text2:""  }
                    ];
                    this.personal_details = [
                        { label: "<b> Full Name </b> <b style='float:right'>:</b>", value: this.employee.full_name,text:"",text2:""  },
                        { label: "<b>Date of Birth </b> <b style='float:right'>:</b>",value: this.dateformat(this.employee.dob),text:"",text2:""  } ,
                        { label: "<b>Gender </b> <b style='float:right'>:</b>", value: this.str_title(this.employee.gender),text:"",text2:""  },
                        { label:  "<b>Father Name </b> <b style='float:right'>:</b>",value: this.employee.father_name,text:"",text2:""  } ,
                        { label:  "<b>Mother Name </b> <b style='float:right'>:</b>", value: this.employee.mother_name,text:"",text2:""  } ,
                        { label: "<b>Marital Status </b> <b style='float:right'>:</b>", value: this.str_title(this.employee.marital_status),text:"",text2:""  } ,
                        { label: "<b>Mobile Number </b> <b style='float:right'>:</b>", value: this.employee.mobile_number,text:"",text2:""  },
                        { label: "<b>Phone Number </b> <b style='float:right'>:</b>", value: this.employee.phone_number,text:"",text2:""  },
                        { label: "<b>Current Address </b> <b style='float:right'>:</b>", value: this.str_title(this.employee.current_address),text:"",text2:""  },
                        { label: "<b>Permanent Address </b> <b style='float:right'>:</b>", value: this.employee.permanent_address,text:"",text2:""  },
                        { label: "<b>Personal Email </b> <b style='float:right'>:</b>",  value: this.employee.personal_email,text:"",text2:""  },
                        { label: "<b>Aadhaar Number </b> <b style='float:right'>:</b>", value: this.employee.aadhaar_number,text:"",text2:""  },
                        { label: "<b>PAN Number </b> <b style='float:right'>:</b>",  value: this.employee.pancard_number,text:"",text2:""  },
                        { label: "<b>Passport Number </b> <b style='float:right'>:</b>", value: this.employee.passport_number,text:"",text2:""  },
                        { label: "<b>Blood Group </b> <b style='float:right'>:</b>", value: this.employee.blood_group,text:"",text2:""  },
                        { label: "<b>UAN Number </b> <b style='float:right'>:</b>", value: this.employee.uan,text:"",text2:""  },
                        { label: "<b>ESIC Number </b> <b style='float:right'>:</b>", value: this.employee.esic,text:"",text2:""  },
                    ];
                    this.bank_details = [
                        { label: "<b> Bank Name </b> <b style='float:right'>:</b>", value: this.employee.bank_name,text:"",text2:""  },
                        { label: "<b>Bank Account Number </b> <b style='float:right'>:</b>",value: this.employee.bank_account_number,text:"",text2:""  } ,
                        { label: "<b>IFSC Code </b> <b style='float:right'>:</b>", value: this.employee.ifsc_code,text:"",text2:""  },
                    ];
                    this.updateProgressBar(true);
                    this.page_loader = false;
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.page_loader = false;
                    this.showAlert();
                });
        },

        getEmployeeSalary() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = {
                id: this.$route.params.id
            };
            this.axios
                .get(this.$api.get_employee_salaries, query)
                .then(response => {
                    let result = response.data.data;
                        this.salary_details = [
                        { label: "<b>Offered Gross per Year </b><b style='float:right'>:</b>",  value: result.offered_gross_per_year,text:"",text2:"" } ,
                        { label: "<b>Offered Gross per Month </b><b style='float:right'>:</b>",  value: result.offered_gross_per_month,text:"",text2:"" },
                        { label:  "<b>Effective From </b><b style='float:right'>:</b>", value: this.dateformat(result.effective_from),text:"",text2:"" } ,
                    ];
                    this.api_error = "";
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        hashTags(value){
            let data= JSON.parse(value);
            let hash_tags = "";
            data.forEach(tag => {
                                    hash_tags += tag.trim() + ', ';
                                });
            return hash_tags;
        },

        setBadgeColor(value) {
            let data = this.badge_colors.find(status => status.status == value);
            return data.color;
        },

        dateformat (date) {
            var dateFormat = new Date(date);
            var dd = String(dateFormat.getDate()).padStart(2, '0');
            var mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
            var yyyy = dateFormat.getFullYear();
            return dd + '-' + mm + '-' + yyyy;
        },

        getRole(data) {
            data = data.toString();
            return data.split(',').join(', ');
        }
    }
};
</script>

<style lang="scss">
    @import "../../../assets/css/custom.scss";

table td { 
  width: 10%;
  }
</style>
